import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "src/assets/config/config";
import { map, take } from "rxjs/operators";
import { BehaviorSubject, of } from "rxjs";
import { ApiService } from "../Shared/Services/Api.services";
import { Question, SecretQuestionVm } from "./models/secretQuestionsVm";
import { ProfilePhotoUpload } from "./models/profilePhotoUploadVm";
import { profileModel } from "./models/profileModel";
import { AuthServices } from "../Auth/auth.service";
import { ForgotPasswordResponseVm } from "../Auth/models/forgotPasswordResponseVm";
import { TWOFASetupResponseVm } from "./models/2FASetUpResponseVm";
import { InitiateChangeDeviceResponse } from "./models/initiateChangeDeviceResponse";
@Injectable({
  providedIn: "root",
})
export class ProfileService extends ApiService {
  private _secretQuestions = new BehaviorSubject<SecretQuestionVm>({
    maximumQuestion: 0,
    questions: [],
  });
  private profilePicture = new BehaviorSubject<string>(null);
  profilePicture$ = this.profilePicture.asObservable();
  secretQuestion$ = this._secretQuestions.asObservable();

  private profile = new BehaviorSubject<profileModel>(null);
  profile$ = this.profile.asObservable();
  baseUrl = `${Config.settings.apiServer.ProfileUrl}`;
  constructor(httpClient: HttpClient, private authService: AuthServices) {
    super(httpClient);
  }
  // get user(){
  //   return this.user.asObservable();
  // }

  setPIN(model: any) {
    return this.post<any>(model, `${Config.settings.apiServer.PINUrl}/Create`);
  }
  getSecurityQuestions() {
    let secretQuestions = this._secretQuestions.value;
    if (secretQuestions && secretQuestions.questions.length > 0)
      return of(secretQuestions);
    return this.GetAll<SecretQuestionVm>(
      `${this.baseUrl}/getSecretQuestions`
    ).pipe(
      map((res) => {
        this._secretQuestions.next(res.payload);
        console.log("secret questions loaded at service level", res.payload);
        return res.payload;
      })
    );
  }
  forGotPIN(answer: any) {
    return this.post<string>(
      answer,
      `${Config.settings.apiServer.PINUrl}/forgot`
    );
  }
  resetPIN(answer: any) {
    return this.post<boolean>(
      answer,
      `${Config.settings.apiServer.PINUrl}/reset`
    );
  }
  validatePIN(model: any) {
    return this.post<boolean>(
      model,
      `${Config.settings.apiServer.PINUrl}/validate`
    );
  }
  changePIN(model: any) {
    return this.post<boolean>(
      model,
      `${Config.settings.apiServer.PINUrl}/change`
    );
  }
  addOrUpdateSecretAnswers(model: any) {
    return this.post<boolean>(
      model,
      `${this.baseUrl}/addOrUpdateSecretAnswers`
    );
  }
  getRandomSecretQuestion() {
    return this.GetAll<Question>(`${this.baseUrl}/getRandomSecretQuestion`);
  }
  GetRandomSecretQuestionByInput(email: string) {
    return this.GetAll<Question>(
      `${this.baseUrl}/GetRandomSecretQuestionByInput?email=${email}`
    );
  }

  initiateChangeDevice(email: string) {
    return this.post<InitiateChangeDeviceResponse>(
      { email },
      `${this.baseUrl}/InitiateChangeDevice`
    );
  }
  forgotPassword(email: string) {
    return this.post<ForgotPasswordResponseVm>(
      { email },
      `${this.baseUrl}/forgotPassword`
    );
  }

  resetPassword(model: any) {
    return this.post<boolean>(model, `${this.baseUrl}/resetPassword`);
  }
  uploadProfilePicture(photoUpload: ProfilePhotoUpload) {
    return this.post<string>(
      photoUpload,
      `${this.baseUrl}/uploadProfilePicture`
    ).pipe(
      map((response) => {
        this.authService.setProfilePhoto(response.payload);
        this.profilePicture.next(response.payload);
        return response.payload;
      })
    );
  }
  changeDevice(model: any) {
    return this.post<boolean>(model, `${this.baseUrl}/changeDevice`);
  }
  setUP2FA() {
    return this.GetAll<TWOFASetupResponseVm>(`${this.baseUrl}/SetUp2FA`);
  }
  enable2FA(model: any) {
    return this.post<boolean>(model, `${this.baseUrl}/enable2FA`).pipe(
      map((res) => {
        let profile = this.profile.value;
        if (profile) {
          profile.is3rdParty2FAEnabled = res.payload;
        }
        return res;
      })
    );
  }
  getProfile() {
    let profile = this.profile.value;
    if (profile) {
      this.authService.setProfilePhoto(profile.profilePicture);
      return of(profile);
    }
    return this.GetAll<profileModel>(`${this.baseUrl}/getProfile`).pipe(
      map((res) => {
        this.profile.next(res.payload);
        if (res.payload) {
          this.authService.setProfilePhoto(res.payload.profilePicture);
        }
        return res.payload;
      })
    );
  }
}
