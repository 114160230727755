import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertPage } from '../components/alert.page';

@Injectable({
  providedIn: 'root'
})

export class AlertService {

    constructor(private modalCtrl: ModalController) {}

    showAlert(type, text) {
        this.modalCtrl.create({
            component: AlertPage,
            componentProps: {type, text},
            cssClass: 'alert-box'
        }).then(modalEl => {
            modalEl.present()
        });
    }

}
