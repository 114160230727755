
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthServices } from '../Auth/auth.service';


@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(public auth: AuthServices, public router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    console.log('IsAuthenticated Value',this.auth.isAuthenticated());
    if (!this.auth.isAuthenticated()) {   
    this.router.navigate(['/', 'auth','login'], {
      queryParams: {
        next: state.url
      }
    });
    }

    return this.auth.isAuthenticated();
  }

}
