import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CreditCardValidators } from 'angular-cc-library';

@Component({
    templateUrl: '../views/add-card.html',
    styleUrls: ['../styles/add-card.scss']
})
export class AddCardPage implements OnInit{

    form: FormGroup;

    constructor(private modalCtrl: ModalController, private _fb: FormBuilder){
    }

    ngOnInit() {
        this.form = this._fb.group({
            creditCard: ['', [CreditCardValidators.validateCCNumber]],
            expirationDate: ['', [CreditCardValidators.validateExpDate]],
            cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]] 
          });
    }

    addCard() {
        this.modalCtrl.dismiss();
    }

    onCancel() {
        this.modalCtrl.dismiss();
    }

    onSubmit() {
        console.log(this.form.value);
        this.modalCtrl.dismiss();
      }
    
}