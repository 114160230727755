import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { CountdownEvent } from "ngx-countdown";
import { Subscription } from "rxjs";
import { Challenge, AuthenticationTypes } from "../models/challenge.model";
import { ChallengeValues } from "../models/challengeValues";
import { TransferAuthChallengeEnum } from "../models/enums";
import { ChallengeService } from "../Services/challenge.service";


@Component({
templateUrl: '../views/challenge.html',
selector: 'user-challenges',
styleUrls:['../styles/challenges.scss']
})
export class ChallengePage implements OnInit, OnDestroy {
    myFormGroup:FormGroup;
    challengeType= TransferAuthChallengeEnum;
    @ViewChild('templateRef')templateRef:any
    challengeValues:ChallengeValues[]= [];
    @Input('amount') amount:number=0;
    authenticationType: AuthenticationTypes;
    requiredChallenges:Challenge[]= [];
    otherChallenges :Challenge[]=[];
    inputChallenges = [];
    showOptionalChallenges: boolean= false;
    showOptionalChallengesInput:boolean=false;
    optionalChallenges:Challenge[]=[];
    optionalIndex:number;
    resendBtn:boolean = true;
    timer: boolean;
    errorMessage:string;
    allChallenges: AuthenticationTypes[]=[];
    filteredChallenges: AuthenticationTypes[] = [];
    private challengeSub: Subscription;

    constructor(public challengeService: ChallengeService,
        public modalCtrl: ModalController){}
    ngOnInit(): void {
      console.log(this.inputChallenges);
      this.challengeSub = this.challengeService.getChannelChallenges().subscribe((result)=>{
          console.log('Challenge result',result);
          this.allChallenges = result;
          this.filteredChallenges = this.allChallenges;
          this.authenticationType  =  this.filteredChallenges.find(x=>this.amount>=x.minLimit && this.amount<=x.maxLimit);
          // this.authenticationType = transactionChallenge;
          if(this.authenticationType.challengeCount >=2){
            
            if (this.inputChallenges.length > 0) {
              console.log('Using input challenges');
              if (this.inputChallenges.length>0) {
                this.authenticationType.challenges = this.authenticationType.challenges.filter(x=> 
                  this.inputChallenges.find(challenge=>challenge.id === x.id));
                  this.authenticationType.challengeCount = this.inputChallenges.length;
                  this.authenticationType.challenges[0].userMessage = this.inputChallenges[0].userMessage;
                  console.log('Filtered challenges', this.authenticationType.challenges);
              }
              }
                this.requiredChallenges = this.authenticationType.challenges.filter(x=>x.isRequired);
                this.otherChallenges = 
                this.authenticationType.challenges.filter(x=>!x.isRequired);
                
              if(this.requiredChallenges.length<1) {
                if (this.otherChallenges.length === 1) {
                  this.optionalChallenges = this.otherChallenges;
                  console.log(this.optionalChallenges);
                  
                  // this.showOptionalChallenges = true;
  
                  this.showOptionalChallengesInput = true;
                } else {
                  this.showOptionalChallenges = true;
                }
              }
            
          } else {
            this.requiredChallenges = this.authenticationType.challenges.filter(x=>x.isRequired);
            this.otherChallenges = 
                this.authenticationType.challenges.filter(x=>!x.isRequired);
          }
        }, err=> {
          console.log(err);
          this.dismiss();
        });
        
    }

    onInputChanged(e, challenge: Challenge){
        if(e.length ===challenge.textLength){
            const challengeValue:ChallengeValues = {
              id:challenge.id,
              value: e, 
              isRequired: challenge.isRequired
            };
            let index = this.challengeValues.findIndex(x=>x.id == challenge.id);
            if(index>-1){
              this.challengeValues[index].value = e;
            }
            else{
                this.challengeValues.push(challengeValue);
            }
            if(challenge.isRequired && this.otherChallenges.length > 0){
                if (this.otherChallenges.length === 1) {
                  this.onUserPreferedOptionSelected(0, this.otherChallenges[0]);
                } else {
                  this.showOptionalChallenges = true;
                }
            } else {
              this.validateChallenge();
            }
        }
    }
    async presentModal(data:any) {
        const modal = await this.modalCtrl.create({
          component: data,
          cssClass: 'my-custom-class'
        });
        return await modal.present();
      }
      onUserPreferedOptionSelected(i, challenge: Challenge){
        // this.showOptionalChallenges = false;
        this.optionalIndex = i;
        if(this.authenticationType.challengeCount === this.challengeValues.length){
            let Index = this.challengeValues.findIndex(x=>!x.isRequired);
            this.challengeValues[Index].id = challenge.id;
        }
        this.optionalChallenges = this.otherChallenges.filter(x=>x.id === challenge.id);
       if((challenge.name === this.challengeType.OTP && this.inputChallenges.length<1) || (challenge.name === this.challengeType.OTP && this.authenticationType.challengeCount>1)){  
         console.log(challenge.name, this.challengeType.OTP);
         
         let Index = this.challengeValues.findIndex(x=>!x.isRequired && x.id == challenge.id);
          if(Index <=-1){   
        const challengeValue:ChallengeValues ={
            id:challenge.id,
            value: '',
            isRequired: false
          };
          this.challengeValues.push(challengeValue);
          console.log(this.challengeValues);
        }
       this.validateChallenge();
       }
       else{
           this.showOptionalChallengesInput = true;
           console.log('Other Challenge Input',this.optionalChallenges);
       }
      }
      validateChallenge(){
        console.log('Challenge Values',this.challengeValues);
        
          if(this.challengeValues.length ===this.authenticationType.challengeCount){
            this.modalCtrl.dismiss(this.challengeValues, 'confirm');
          }
      }

      onResendOtp() {
        // if (!this.user) {
        //     return this.appStepper.previous();
        // }        
        // this.onboardingService.resendOTP({
        //     reference: this.user.reference,
        //     otpReference: this.user.OtpReference
        // }).subscribe(res => {
        //     this.countdown.restart();
        // });  
        this.modalCtrl.dismiss(null, 'resend-otp');  
    }
    handleEvent(e:CountdownEvent) {
        if (e.action === 'restart') {
            this.resendBtn = false;
        }
        if (e.action === 'done') {
            this.resendBtn = true;
        }
    }

      dismiss() {
        this.modalCtrl.dismiss();
      }

      ngOnDestroy() {
        // if (this.challengeSub) {
        //   this.challengeSub.unsubscribe();
        // }
      }
}