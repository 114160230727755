import { Component, Input } from "@angular/core";
import { AuthServices } from "src/app/Auth/auth.service";
import { ProfileService } from "src/app/Profile/profileService";

@Component({
  templateUrl: "../views/header.html",
  selector: "app-header",
  styleUrls: ["../styles/header.scss"],
})
export class HeaderPage {
  @Input() color: string = "secondary";
  @Input() title: string;
  @Input() sideMenu: boolean = false;
  @Input() defaultHref: string | [] = "/";
  @Input() showImage: boolean = false;

  greet: string = "";
  constructor(public auth: AuthServices, public profile: ProfileService) {
    this.greet = this.getTime();
  }

  getTime(): string {
    const myDate = new Date();
    const hrs = myDate.getHours();
    let greet;
    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
    return greet;
  }
}
