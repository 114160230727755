import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AuthServices } from "src/app/Auth/auth.service";

@Component({
    templateUrl:'../views/side-menu.html',
    selector : 'app-side-menu',
    styleUrls:['../styles/side-menu.scss']
})

export class SideMenuPage implements OnInit{
    @Input() type;
    @Input() text;
    greet:string = '';

    constructor(public auth: AuthServices){
        this.greet= this.getTime();
    }
    
    ngOnInit(): void {
    }

    getTime(): string{
        const myDate = new Date();
        const hrs = myDate.getHours();
        let greet;
        if (hrs < 12)
            greet = 'Good Morning';
        else if (hrs >= 12 && hrs <= 17)
            greet = 'Good Afternoon';
        else if (hrs >= 17 && hrs <= 24)
            greet = 'Good Evening';
            return greet
       }

       onLogOut() {
        this.auth.logOff;
       }

}