import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { StepperPage } from "./components/stepper.page";
import { TextInputPage } from "./components/text-input.page";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { NgOtpInputModule } from "ng-otp-input";
import { HeaderPage } from "./components/header.page";
import { ChallengePage } from "./components/Challenge.page";
import { BeneficiaryPage } from "./components/beneficiary.page";
import { AccountsCardPage } from "./components/accounts.card.page";
import { NotificationPage } from "./components/notification.page";
import { AdvertPage } from "./components/advert.page";
import { SideMenuPage } from "./components/side-menu.page";
import { AccountPaymentPage } from "./components/account-payment.page";
import { CardPaymentPage } from "./components/card-payment.page";
import { AddCardPage } from "./components/add-card.page";
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { CountdownModule } from "ngx-countdown";
import { TransactionStatusPage } from "./components/transaction-status.page";
import { PopMenuComponent } from "./components/pop-menu.page";
import { ImagePickerComponent } from "./components/image-picker.component";
import { FileUploadComponent } from "./components/file-upload.component";
import { AlertPage } from "./components/alert.page";
import { UploadDirective } from "./directives/upload.directive";
import { FormFieldPage } from "./components/form-field.page";
import { RoleDirective } from "./directives/role-directive";
import { BanksPage } from "./components/banks.page";
import { GetFirstWord } from "./pipes/firstword.pipe";
import { MatRadioModule } from "@angular/material/radio";
import { AccountDetailsPage } from "./components/account-details.page";
import { CardFundingPage } from "./components/card-funding.page";
import { PaymentMethodPage } from "./components/payment-method.page";
import { Angular4PaystackModule } from "angular4-paystack";
import { environment } from "src/environments/environment";
import { FundAccountReviewPage } from "./components/fundAccountReview.page";
import { SecurityQueryPage } from "./components/securityQuery.page";
export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  allowZero: false,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ",",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};
@NgModule({
  declarations: [
    StepperPage,
    TextInputPage,
    HeaderPage,
    SecurityQueryPage,
    ChallengePage,
    BeneficiaryPage,
    AccountsCardPage,
    NotificationPage,
    AdvertPage,
    AccountDetailsPage,
    GetFirstWord,
    SideMenuPage,
    AccountPaymentPage,
    CardFundingPage,
    CardPaymentPage,
    FormFieldPage,
    AddCardPage,
    TransactionStatusPage,
    AlertPage,
    UploadDirective,
    BanksPage,
    RoleDirective,
    ImagePickerComponent,
    FileUploadComponent,
    PopMenuComponent,
    PaymentMethodPage,
    FundAccountReviewPage,
  ],
  imports: [
    RouterModule,
    IonicModule,
    FormsModule,
    NgxMatIntlTelInputModule,
    NgOtpInputModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    CommonModule,
    CountdownModule,
    CdkStepperModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ReactiveFormsModule,
    Angular4PaystackModule.forRoot(environment.PayStackKey),
  ],
  exports: [
    RouterModule,
    IonicModule,
    SecurityQueryPage,
    FormsModule,
    NgxMatIntlTelInputModule,
    NgOtpInputModule,
    MatFormFieldModule,
    MatInputModule,
    RoleDirective,
    MatIconModule,
    MatRadioModule,
    FormFieldPage,
    MatSelectModule,
    NgxCurrencyModule,
    GetFirstWord,
    MatDatepickerModule,
    UploadDirective,
    MatNativeDateModule,
    CountdownModule,
    CommonModule,
    CdkStepperModule,
    AccountDetailsPage,
    StepperPage,
    BanksPage,
    TextInputPage,
    HeaderPage,
    BeneficiaryPage,
    ChallengePage,
    AccountsCardPage,
    CardFundingPage,
    NotificationPage,
    ImagePickerComponent,
    AlertPage,
    FileUploadComponent,
    AdvertPage,
    SideMenuPage,
    AddCardPage,
    CardPaymentPage,
    AccountPaymentPage,
    ReactiveFormsModule,
    TransactionStatusPage,
    PopMenuComponent,
    PaymentMethodPage,
    Angular4PaystackModule,
    FundAccountReviewPage,
  ],
})
export class SharedModule {}
