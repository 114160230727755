import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { CameraResultType, CameraSource, Capacitor, Plugins } from '@capacitor/core';
import { FilePickVm } from '../models/filePickVm.model';
import { ImagePickVm } from '../models/imagePickModel';
import { AlertService } from '../Services/alert.service';

@Component({
  selector: "app-upload",
  templateUrl: "../views/file-upload.html",
  styleUrls: ["../styles/file-upload.scss"],
})
export class FileUploadComponent implements OnInit {
  @Input() showPreview = false;
  @Input() allowedExtensions = ['.doc','.docx', '.ppt', '.pptx', '.txt', '.pdf'];
  @ViewChild("filePicker") filePickerRef: ElementRef<HTMLInputElement>;
  @Output() filePick = new EventEmitter<FilePickVm | File>();
  @Output() fileClear = new EventEmitter<boolean>();
  selectedFile:any = null;
  selectedFileString: string;
  isUploading = false;
  payload = new FormData();

  constructor(public alertService: AlertService) {}

  ngOnInit() {}

  onFileChosen(event: Event) {
    const pickedFile = (event.target as HTMLInputElement).files[0];
    this.selectedFile = pickedFile;
    this.getBase64(pickedFile);
  }

  onFileDropped(evt) {
    const pickedFile = evt[0];
    this.selectedFile = pickedFile;
    this.getBase64(pickedFile);
  }

  getBase64(file) {
    if (!file) {
      return;
    }
    const format = file.name.split(".").pop();
    if (
      !this.allowedExtensions.some(
        (item) => `${item.toLowerCase()}` == `.${format.toLowerCase()}`
      )
    ) {
      this.alertService.showAlert(
        "error",
        `Only ${this.allowedExtensions.join(" ")} files are allowed`
      );
      this.selectedFile = null;
      this.selectedFileString = null;
      this.fileClear.emit(true);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const dataUrl = reader.result.toString();
      this.selectedFileString = dataUrl;
      const format = file.name.split(".").pop();
      const filePicked: FilePickVm = {
        file: this.selectedFileString,
        blob: file,
        format: format,
      };

      this.filePick.emit(filePicked);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
}
