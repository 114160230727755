import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TransferService } from "src/app/Payments/transfer/transfer.service";
import { Banks } from "../models/banks.model";
import { BanksService } from "../Services/banks.service";

@Component({
  templateUrl: "../views/banks.html",
  styleUrls: ["../styles/banks.scss"],
})
export class BanksPage implements OnInit {
  @Input() banks: Banks[];
  @Input() bank: Banks;
  filteredBanks: Banks[];

  constructor(
    private bankService: BanksService,
    private modalCtrl: ModalController,
    private transferService: TransferService
  ) {}

  ngOnInit() {
    this.bankService.getBanks().subscribe((res) => {
      this.banks = res;
      this.filteredBanks = this.banks.sort(function (a, b) {
        if (a.bank < b.bank) {
          return -1;
        }
        if (a.bank > b.bank) {
          return 1;
        }
        return 0;
      });
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onInputChange($event) {
    let search = $event.detail.value;
    this.filteredBanks = this.banks
      .filter(
        (x) => x.bank.trim().toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
      .sort(function (a, b) {
        if (a.bank < b.bank) {
          return -1;
        }
        if (a.bank > b.bank) {
          return 1;
        }
        return 0;
      });
  }

  selectBank(bank) {
    this.modalCtrl.dismiss(bank, "confirm");
  }
}
