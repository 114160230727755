// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: "dev",
  BaseUrl: "https://sandbox.moolahvest.com",
  AuthUrl: "https://sandbox.moolahvest.com/authenticate",
  SavingsAndLoansUrl: "https://sandbox.moolahvest.com/lending/api/v1",
  SavingsUrl: "https://sandbox.moolahvest.com/savings/api/v1",
  LendingUrl: "https://sandbox.moolahvest.com/loans/api/v1",
  PaymentsUrl: "https://sandbox.moolahvest.com/payment",
  OnboardingUrl: "https://sandbox.moolahvest.com/onboard/api/v1",
  BillsUrl: "https://sandbox.moolahvest.com/billpayments/api/v1",
  ProfileUrl: "https://sandbox.moolahvest.com/Profile/api/v1/Profile",
  PINUrl: "https://sandbox.moolahvest.com/Profile/api/v1/PIN",
  ChallengeUrl: "https://sandbox.moolahvest.com/challenge/api/v1",
  Url: "api/users",
  redirectUrl: "http://localhost:4200/accounts/login",
  silentRenewUrl: "http://localhost:4200",
  clientId: "MoolahWeb_6bcc1b90-f895-47e7-93f6-16f1c00e4c70",
  clientSecret: "YjEwYTJiMGItMWM5Mi00ZTY4LTg1NTMtNjRmZTU2Y2EzNTE2",
  mobileClientId: "MoolahVest-Mobile_e3d26340-9de8-47b7-96a0-0eaaf7cd7a3f",
  mobileClientSecret: "ZGVkNDYxYzItZDhkZi00ZTFlLTk5YjAtMTk5Yzg1ZWM1Nzdi",
  ApiGw: "https://sandbox.moolahvest.com",
  InvestmentUrl: "https://sandbox.moolahvest.com/Investment",
  AccountUrl: "https://sandbox.moolahvest.com/accounts",
  agentMgmt: "https://sandbox.moolahvest.com/agents",
  scope:
    "openid profile email Lending Payments Savings PaymentsApiGw Investment",
  storeFront: "https://localhost:44311",
  PayStackKey: "pk_test_ad3862d0dbeec787fc4bb9110caffe5160b58205",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
