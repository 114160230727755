import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of, pipe } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { ApiService } from "src/app/Shared/Services/Api.services";
import { Config } from "src/assets/config/config";
import {
  AccountsHistory,
  AccountsModel,
  TransactionHistory,
  TransactionHistoryRequest,
} from "../models/accountmocel";

@Injectable({
  providedIn: "root",
})
export class AccountServices extends ApiService {
  baseUrl = `${Config.settings.apiServer.AccountUrl}/api/v1/accounts`;
  private currentAccountSource = new BehaviorSubject<AccountsModel>(null);
  currentAccount$ = this.currentAccountSource.asObservable();
  private accountSource = new BehaviorSubject<AccountsModel[]>(null);
  // private _transactionHistory = new BehaviorSubject<TransactionHistory[]>([]);
  private _accountHistory = new BehaviorSubject<AccountsHistory[]>([]);
  accounts$ = this.accountSource.asObservable();
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  GetAllAccounts(isDashboard: boolean) {
    console.log("IsDashboard", isDashboard);
    if (isDashboard) return this.GetCustomerAccounts();
    const accounts = this.accountSource.value;
    if (accounts && accounts.length > 0) return of(accounts);
    return this.GetCustomerAccounts();
  }
  GetAccount(accountNumber: string) {
    const accounts = this.accountSource.value;
    if (accounts && accounts.length > 0)
      return of(accounts.find((x) => x.accountNumber === accountNumber));
  }
  private GetCustomerAccounts() {
    return this.GetAll<AccountsModel[]>(
      `${this.baseUrl}/customeraccounts`
    ).pipe(
      map((res) => {
        this.accountSource.next(res.payload);
        this.currentAccountSource.next(res.payload[0]);
        return res.payload;
      })
    );
  }

  updateCurrentAccount(i) {
    this.currentAccountSource.next(this.accountSource.value[i]);
  }

  get accountsHistory() {
    return this._accountHistory.asObservable();
  }

  fetchTransactionHistory(toBankAccountReq: TransactionHistoryRequest) {
    const accounts: AccountsModel[] = [...this.accountSource.value];
    if (this._accountHistory.value.length < 1) {
      accounts.forEach((x) => {
        this._accountHistory.next([
          ...this._accountHistory.value,
          { accountNumber: x.accountNumber, transactionHistory: [] },
        ]);
      });
    }
    return this.post<TransactionHistory[]>(
      toBankAccountReq,
      `${this.baseUrl}/transactionHistory`
    ).pipe(
      map((res) => {
        const accountRecords = this._accountHistory.value.findIndex(
          (x) => x.accountNumber === toBankAccountReq.sourceAccount
        );
        let allRecords: AccountsHistory[] = [...this._accountHistory.value];
        allRecords[accountRecords].transactionHistory = res.payload;
        return res.payload;
      })
    );
  }
  requestStatement(model: any) {
    return this.GetDataWithFilter<boolean>(
      model,
      `${this.baseUrl}/GenerateStatement`
    );
  }
}
