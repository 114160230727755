import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../Services/Api.services';
import { Config } from '../../../assets/config/config';
import { Beneficiary, BeneficiaryTypes } from '../models/beneficiarymodel';

@Injectable({
 providedIn: 'root'
})
export class BeneficiaryService extends ApiService{
    baseUrl = `${Config.settings.apiServer.PaymentsUrl}`;
    private _beneficiaries = new BehaviorSubject<Beneficiary[]>([]);
    private _filterBeneficiaries= new BehaviorSubject<Beneficiary[]>([]);
    filteredBeneficiaries$ = this._filterBeneficiaries.asObservable();
    constructor(httpClient: HttpClient) {
        super(httpClient);
      } 
      get beneficiaries(){
          return this._beneficiaries.asObservable();
      }
      
    getBeneficiaries(){
        return this.beneficiaries.pipe(take(1), switchMap(beneficiaries=> {
           if(!beneficiaries || beneficiaries.length <=0){
              return this.fetchBeneficiaries();
           } else{
               return of(beneficiaries);
           }
        }));
    }

    AddBeneficiary(beneficiary: Beneficiary) {
        console.log('Add Beneficiary Got Here',beneficiary);
        var beneficiaries =this._beneficiaries.value;
        if (!beneficiaries.find(x=>x.value===beneficiary.value && x.beneFiciaryType===beneficiary.beneFiciaryType && x.productCode===beneficiary.productCode)) {
            this._beneficiaries.next([...beneficiaries, beneficiary])
        };
    }

    removeBeneficiary(beneficiary: Beneficiary) {
        console.log(this._beneficiaries.value);
        const beneficiaries: Beneficiary[] =this._beneficiaries.value;
        this._beneficiaries.next(beneficiaries.filter(x=>beneficiary != x));
        
    }

    private fetchBeneficiaries(){
        return this.GetAll<Beneficiary[]>(`${this.baseUrl}/api/Beneficiaries`)
        .pipe(
            map( resData => {
             return resData.payload;
        }),
        tap(beneficiaries => {
            console.log(beneficiaries);
            
         this._filterBeneficiaries.next(beneficiaries);
         this._beneficiaries.next(beneficiaries);
        }));
    }
    
}
