import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./tabs/tabs.module").then((m) => m.TabsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "onboard",
    loadChildren: () =>
      import("./Onboarding/onboarding.module").then((m) => m.OnboardingModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./Auth/auth.module").then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
