import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { fromEvent, Subscription } from "rxjs";
import { AccountsModel } from "src/app/Accounts/models/accountmocel";
import { AccountServices } from "src/app/Accounts/services/account.services";
import { FundAccountResponseVm } from "../models/fundAccountResponseVm";
import { CardPaymentServices } from "../Services/payment.service";
import { CardPaymentPage } from "./card-payment.page";
import { FundAccountReviewPage } from "./fundAccountReview.page";

@Component({
  templateUrl: "../views/card-funding.html",
  selector: "app-card-funding",
  styleUrls: ["../styles/card-funding.scss"],
})
export class CardFundingPage implements OnInit {
  private backbuttonSubscription: Subscription;
  @Input() account: AccountsModel;
  rememberCard = false;
  minAmount = 100;
  paymentForm: FormGroup;
  fundAccountResponse: FundAccountResponseVm;
  constructor(
    public accountService: AccountServices,
    private modalCtrl: ModalController,
    private paymentService: CardPaymentServices,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    console.log(this.account);
    this.paymentForm = this.fb.group({
      amount: ["", [Validators.required, Validators.min(this.minAmount)]],
    });

    const event = fromEvent(document, "backbutton");
    this.backbuttonSubscription = event.subscribe(async () => {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        modal.dismiss();
      }
    });
  }

  ngOnDestroy() {
    this.backbuttonSubscription.unsubscribe();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async selectCard() {
    const modal = await this.modalCtrl.create({
      component: CardPaymentPage,
      componentProps: { isModal: true },
    });
    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return;
  }
  fundAccountWithCardInit() {
    this.paymentService
      .fundAccountWithCardInit({
        amount: this.paymentForm.get("amount")?.value,
        beneficiary: this.account.accountName,
        beneficiaryAccount: this.account.accountNumber,
        beneficiaryBank: this.account.bank,
        beneficiaryBankCode: this.account.bankCode,
        paymentReference: "",
      })
      .subscribe((res) => {
        this.fundAccountResponse = res.payload;
        this.modalCtrl.dismiss();
        this.showPreview(res.payload);
      });
  }
  async fundAccountWithCardComplete() {
    this.paymentService
      .fundAccountWithCardComplete({
        amount: this.paymentForm.get("amount")?.value,
        beneficiary: this.account.accountName,
        beneficiaryAccount: this.account.accountNumber,
        beneficiaryBank: this.account.bank,
        beneficiaryBankCode: this.account.bankCode,
        paymentReference: this.fundAccountResponse.paymentReference,
      })
      .subscribe((res) => {
        this.modalCtrl.dismiss();
        this.accountService.GetAllAccounts(true).subscribe((res) => {});
      });
  }
  async showPreview(fundAccountResponse: FundAccountResponseVm) {
    const modal = await this.modalCtrl.create({
      component: FundAccountReviewPage,
      cssClass: "auto-height",
      swipeToClose: true,
      componentProps: { fundAccountResponse },
    });
    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    if (role === "confirm") {
      this.fundAccountWithCardComplete();
    }
  }
}
