import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  Observable,
  throwError,
  fromEvent,
  merge,
  Observer,
  from,
  TimeoutError,
} from "rxjs";
import {
  retry,
  catchError,
  tap,
  finalize,
  take,
  switchMap,
  timeout,
} from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import {
  AlertController,
  LoadingController,
  ToastController,
} from "@ionic/angular";
import { NetworkStatus, PluginListenerHandle, Plugins } from "@capacitor/core";
import { AuthServices } from "src/app/Auth/auth.service";
import { AlertService } from "../Services/alert.service";
import { Config } from "src/assets/config/config";
import { NgxSpinnerService } from "ngx-spinner";
const { Network } = Plugins;
const { Storage } = Plugins;
@Injectable({
  providedIn: "root",
})
export class HttpRequestInterceptor implements HttpInterceptor {
  loading: HTMLIonLoadingElement;
  message: string;
  networkListener: PluginListenerHandle;
  networkStatus: NetworkStatus;
  private authService: AuthServices;
  private alertService: AlertService;
  requestUrl: string;
  exemptedAlertRoutes = [
    `${Config?.settings?.apiServer?.BillsUrl}/completeWithOTP`,
    `${Config?.settings?.apiServer?.BillsUrl}/resendOTP`,
  ];

  constructor(
    private router: Router,
    private injector: Injector,
    public toastController: ToastController,
    private alertController: AlertController,
    private spinner: NgxSpinnerService,
    public loadingCtrl: LoadingController
  ) {
    this.authService = this.injector.get(AuthServices);
    this.alertService = this.injector.get(AlertService);
    this.networkListener = Network.addListener(
      "networkStatusChange",
      (status) => {
        this.networkStatus = status;
      }
    );
    // To stop listening:
    // handler.remove();

    // Get the current network status
    Network.getStatus().then((status) => {
      this.networkStatus = status;
      console.log("Connection Status", this.networkStatus);
    });
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const jsonFile = `assets/config/config.${environment.name}.json`;
    this.requestUrl = request.url;
    let exemptedLoadingRoutes = [
      `${Config?.settings?.apiServer?.AccountUrl}/api/v1/accounts/customeraccounts`,
      `assets/config/config.${environment.name}.json`,
      `${Config?.settings?.apiServer?.AuthUrl}/connect/token`,
    ];
    if (
      exemptedLoadingRoutes.filter((x) => request.url.includes(x)).length < 1
    ) {
      // this.loadingCtrl.getTop().then(hasLoading=>{
      //     if(!hasLoading){
      //     this.loadingCtrl.create({
      //       spinner:'circular',
      //       translucent:true,
      //       keyboardClose:true,
      //       message: 'Please wait...'
      //     })
      //     .then(loading=> loading.present());
      //   }
      //   });
      this.spinner.show();
    }
    let token;
    this.authService.currentUser$.pipe(take(1)).subscribe((user) => {
      token = user?.token;
      console.log("user Token", token);
    });
    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
    }
    return next.handle(request).pipe(
      retry(0),
      timeout(50000),
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (evt.body) {
            console.log("response", evt.body);
            if (evt.body.description != null && evt.body.description != "") {
              this.presentAlert(
                "success",
                evt.body.description ?? evt.body?.message
              );
            }
          }
        }
        return evt;
      }),
      catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        //   this.loadingCtrl.getTop().then(hasLoading=>{
        //     if(hasLoading){
        //         this.loadingCtrl.dismiss();
        //     }
        // });
        if (error instanceof TimeoutError) {
          return throwError(
            this.presentAlert(
              "error",
              "We Could not connect to the service because of a timeout"
            )
          );
        } else {
          let errorMessage = "";
          console.log("error making calls", error);
          if (error?.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
            // to a toast
            // window.alert(errorMessage);
            console.log("error making calls", error);
            return throwError(this.presentAlert("error", errorMessage));
          } else {
            if ([401, 403].indexOf(error?.status) !== -1) {
              this.router.navigateByUrl("/auth/login");
            }
            console.log("error Response", error?.error);
            if (error?.error == null) {
              console.log("error response", error);
              return throwError(
                this.presentAlert(
                  "error",
                  "could not get data, please try again"
                )
              );
            }
            if (error.error.error != undefined) {
              this.presentAlert("error", error.error.error_description);
              return throwError(error.error);
            }
            errorMessage =
              error.error.message ||
              "Could not reach the service at the moment,please try again";
          }
          errorMessage = errorMessage;
          this.presentAlert("error", errorMessage);
          return throwError(error.error);
        }
      }),
      finalize(() => {
        this.spinner.hide();
        // this.loadingCtrl.getTop().then(hasLoading=>{
        //     if(hasLoading){
        //         this.loadingCtrl.dismiss();
        //     }
        // });
      })
    );
  }
  presentAlert(type, reason) {
    let exemptedAlertRoutes = [
      `${Config?.settings?.apiServer?.BillsUrl}/completeWithOTP`,
      `${Config?.settings?.apiServer?.BillsUrl}/resendOTP`,
      `${Config?.settings?.apiServer?.BillsUrl}/pay`,
      `${Config?.settings?.apiServer?.AuthUrl}`,
      `${Config?.settings?.apiServer?.PaymentsUrl}/api/v1/transfers/toBankAccount`,
      `${Config?.settings?.apiServer?.PaymentsUrl}/api/v1/transfers/completeOTPTransfer`,
      `${Config?.settings?.apiServer?.PaymentsUrl}/api/v1/Transfers/ResendOTP`,
    ];
    if (
      exemptedAlertRoutes.filter((x) => this.requestUrl.includes(x)).length < 1
    )
      this.alertService.showAlert(type, reason);
    // const alert =  this.alertController.create({
    //       header: status + ' Error',
    //       subHeader: 'Subtitle',
    //       message: reason,
    //       buttons: ['OK']
    //   });
    // alert.then(alert=>{
    //        alert.present();
    // });
  }
}
