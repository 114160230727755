import { Injectable } from '@angular/core';
import { Advert } from '../models/advert.model';

@Injectable({
  providedIn: 'root'
})

export class AdvertService {

    constructor() {}
    slideOptions = {
        initialSlide: 1,
        speed: 400,
      };
    private _ads: Advert[] = [
        {image: 'assets/images/phone.jpg', link: 'payments' },
        {image: 'assets/images/african.jpg', link: 'payments/transfer' },
    ]

    get ads() {
        return this._ads;
    }

}
