import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Banks, VerifyAccountRequest, VerifyAccountResponse } from '../models/banks.model';
import { ApiService } from './Api.services';
import { Config } from '../../../assets/config/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BanksService extends ApiService {
    
    baseUrl = `${Config.settings.apiServer.PaymentsUrl}`;
    private _banks = new BehaviorSubject<Banks[]>([]);

    constructor(httpClient: HttpClient, private modalCtrl: ModalController) {
        super(httpClient);
    }

    get banks(){
        return this._banks.asObservable();
    }

    getBanks(){
        return this.banks.pipe(take(1), switchMap(banks=> {
           if(!banks || banks.length <=0){
              return this.fetchBanks();
           } else{
               return of(banks);
           }
        }));
    }

    private fetchBanks(){
        return this.GetAll<Banks[]>(`${this.baseUrl}/api/v1/transfers/getBanks`)
        .pipe(
            map( resData => {
             return resData.payload;
        }),
        tap(banks => {
         this._banks.next(banks);
        }));
    }

    
    verifyAccount(verifyAccount: VerifyAccountRequest) {
        return this.post<VerifyAccountResponse>(verifyAccount,`${this.baseUrl}/api/v1/transfers/verifyAccount`);
    }


}
