import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  Input,
} from "@angular/core";
import { AuthServices } from "src/app/Auth/auth.service";

@Directive({ selector: "[appUserRole]" })
export class RoleDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthServices,
    private viewContainer: ViewContainerRef
  ) {}

  userRoles: string[];

  @Input()
  set appUserRole(roles: string[]) {
    if (!roles || !roles.length) {
      console.log("no roles available");
    }

    this.userRoles = roles;
  }

  ngOnInit() {
    console.log("available Roles", this.userRoles);
    let hasAccess = false;

    if (this.authService.isAuthenticated() && this.userRoles) {
      hasAccess = this.userRoles.some((r) => this.authService.hasRole(r));
    }

    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
