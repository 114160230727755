import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
    templateUrl:'../views/alert.html',
    selector : 'alert-box',
    styleUrls:['../styles/alert.scss']
})

export class AlertPage implements OnInit{
    @Input() type;
    @Input() text;
    alerts = {
        success: {  title: 'Successful',
                    icon: 'success',
                    color: 'success'},
        error: {
            title: 'Error',
            icon: 'error',
            color:'danger'
        },
        warning: {
            title: 'Something went wrong',
            icon: 'error',
            color:'warning'
        }
}
    constructor(private modalCtrl: ModalController){}
    
    ngOnInit(): void {
    }

    onClose() {
        this.modalCtrl.dismiss();
    }
}