import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Beneficiary, BeneficiaryTypes } from 'src/app/Shared/models/beneficiarymodel';
import { ApiService } from 'src/app/Shared/Services/Api.services';
import { BeneficiaryService } from 'src/app/Shared/Services/beneficiary.services';
import { Config } from 'src/assets/config/config';
import { Banks, CompleteOtpTransfer, ResendOtpRequest, ToBankAccountRequest, ToBankAccountResponse, VerifyAccountRequest, VerifyAccountResponse } from './transfer.model';



@Injectable({
 providedIn: 'root'
})
export class TransferService extends ApiService{
    baseUrl = `${Config.settings.apiServer.PaymentsUrl}`;
    private _banks = new BehaviorSubject<Banks[]>([]);
    private _beneficiaries = new BehaviorSubject<Beneficiary[]>([]);
    constructor(httpClient: HttpClient,
                private beneficiaryService: BeneficiaryService
        ) {
        super(httpClient);
      }
      get banks(){
          return this._banks.asObservable();
      }
      get beneficiaries(){
          return this._beneficiaries.asObservable();
      }
      
    getBanks(){
        return this.banks.pipe(take(1), switchMap(banks=> {
           if(!banks || banks.length <=0){
              return this.fetchBanks();
           } else{
               return of(banks);
           }
        }));
    }

    getBeneficiaries(){
        return this.beneficiaries.pipe(take(1), switchMap(beneficiaries=> {
           if(!beneficiaries || beneficiaries.length <=0){
              return this.fetchBeneficiaries();
           } else{
               return of(beneficiaries);
           }
        }));
    }
    AddBeneficiary(beneficiary: Beneficiary){
        var beneficiaries =this._beneficiaries.value;
          beneficiaries.push(beneficiary);
    }
    toBankAccount (toBankAccountReq: ToBankAccountRequest){
        return this.post<ToBankAccountResponse>(toBankAccountReq,`${this.baseUrl}/api/v1/transfers/toBankAccount`);
    }

    verifyAccount(verifyAccount: VerifyAccountRequest) {
        return this.post<VerifyAccountResponse>(verifyAccount,`${this.baseUrl}/api/v1/transfers/verifyAccount`);
    }

    completeWithOtp(otpRequest: CompleteOtpTransfer) {
        return this.post<ToBankAccountResponse>(otpRequest,`${this.baseUrl}/api/v1/transfers/completeOTPTransfer`);
    }

    resendOtp(resendOtpRequest: ResendOtpRequest) {
        return this.post<any>(resendOtpRequest,`${this.baseUrl}/api/v1/Transfers/ResendOTP`);
    }

    private fetchBanks(){
        return this.GetAll<Banks[]>(`${this.baseUrl}/api/v1/transfers/getBanks`)
        .pipe(
            map( resData => {
             return resData.payload;
        }),
        tap(banks => {
         this._banks.next(banks);
        }));
    }

    private fetchBeneficiaries(){
        return this.beneficiaryService.getBeneficiaries().pipe(
            map(resData=> {
                resData = resData.filter(x=>x.beneFiciaryType === BeneficiaryTypes.Transfer);
                return resData;
        }),
        tap(beneficiaries => {
         this._beneficiaries.next(beneficiaries);
        }));
    }
    
}
