import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { CardVm } from "../models/cardVm";
import { CardPaymentService } from "../Services/card-payment.service";
import { CardPaymentServices } from "../Services/payment.service";
import { AddCardPage } from "./add-card.page";

@Component({
  selector: "card-payment",
  templateUrl: "../views/card-payment.html",
  styleUrls: ["../styles/card-payment.scss"],
})
export class CardPaymentPage implements OnInit {
  @Output() cardSelect = new EventEmitter<string>();
  @Input() activeCardIndex;
  @Input() isModal = false;
  cards: CardVm[] = [];
  constructor(
    private modalCtrl: ModalController,
    public cardService: CardPaymentServices
  ) {}

  ngOnInit() {
    this.cardService.GetUserCards(true).subscribe((data) => {
      this.cards = data;
    });
  }

  onSelect(i) {
    this.activeCardIndex = i;
    if (this.isModal) {
      // this.cardSelect.emit('selected');
      this.modalCtrl.dismiss(this.cards[i], "card-select");
    }
  }

  addCard() {
    this.modalCtrl
      .create({
        component: AddCardPage,
        showBackdrop: true,
      })
      .then((modalEl) => {
        modalEl.present();
      });
  }

  onCancel() {
    this.modalCtrl.dismiss();
  }
}
