import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountFundMethod } from '../models/enums';

@Component({
    templateUrl: '../views/payment-method.html',
    styleUrls: ['../styles/payment-method.scss']
})
export class PaymentMethodPage{
    paymentMethod = AccountFundMethod;

    constructor(public modalCtrl: ModalController){
    }

    selectMethod(method) {
        this.modalCtrl.dismiss(method, 'confirm');
    }

}