import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TransferBeneficiaries } from "src/app/Payments/transfer/transfer.model";
import { BeneficiaryFilter } from "../models/beneficiaryFilterModel";
import { Beneficiary, BeneficiaryTypes } from "../models/beneficiarymodel";

@Component({
    templateUrl:'../views/beneficiary.html',
    styleUrls:['../styles/beneficiary.scss']
})
export class BeneficiaryPage implements OnInit {
    beneficiaryId:number;
    @Input() beneficiaries:Beneficiary[] =[];
    @Input() beneficiaryFilter: BeneficiaryFilter;
    filterBeneficiaries:Beneficiary[];
    queryBeneficiaries: Beneficiary[];
    constructor(private modalCtrl: ModalController){}
    search:string;
    ngOnInit(): void {
       this.queryBeneficiaries= this.beneficiaries;
       this.queryBeneficiaries= this.beneficiaries.filter(x=>x.beneFiciaryType=== this.beneficiaryFilter.beneficiaryType);
       console.log('Beneficiary Filer',this.beneficiaryFilter);
       if(this.beneficiaryFilter.productCode){
         this.queryBeneficiaries= this.queryBeneficiaries.filter(x=>x.productCode===this.beneficiaryFilter.productCode);
       }
       this.filterBeneficiaries= this.queryBeneficiaries;
    }
    dismiss() {
        this.modalCtrl.dismiss({
          'beneficiary': true
        });
      }
      selectBeneficiary(beneficiary: Beneficiary){
          this.beneficiaryId= beneficiary.id;
        this.modalCtrl.dismiss({
             beneficiary
          }, 'confirm'); 

      }
      onInputChange($event){
          console.log('search value Typed',$event);
          this.search = $event.detail.value;
        //   console.log('Search Term Value',this.search);
        //   this.filterBeneficiaries= 
        //   this.beneficiaries.filter(x=>x.name.
        //     trim().toLowerCase()
        //   .indexOf(this.search.toLowerCase())!== -1
        //  || x.product.trim().toLowerCase().indexOf(this.search.toLowerCase())!==-1);
        this.filterBeneficiary();
        
      }

      filterBeneficiary(){
        this.filterBeneficiaries = this.queryBeneficiaries.filter(x=>x.name.
            trim().toLowerCase()
          .indexOf(this.search.toLowerCase())!== -1
         || x.product.trim().toLowerCase().indexOf(this.search.toLowerCase())!==-1
         || x.value.trim().toLowerCase().indexOf(this.search.toLowerCase())!==-1
         );
      }

}