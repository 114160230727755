import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { FundAccountResponseVm } from "../models/fundAccountResponseVm";
import { PaystackOptions } from "angular4-paystack";
import { AuthServices } from "src/app/Auth/auth.service";
import { take } from "rxjs/operators";
import { IUser } from "src/app/Auth/models/IUser";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "../views/fundAccountReview.html",
  styleUrls: ["../styles/fundReview.scss"],
})
export class FundAccountReviewPage implements OnInit {
  options: PaystackOptions;
  constructor(private modalCtrl: ModalController, private auth: AuthServices) {}
  @Input() fundAccountResponse: FundAccountResponseVm;
  user: IUser;
  amount: number = 0;
  amountToCreditAccount = 0;
  ngOnInit(): void {
    this.auth.currentUser$.pipe(take(1)).subscribe((user) => {
      this.user = user;
    });
    this.amount = this.fundAccountResponse?.amount * 100;
    this.amountToCreditAccount =
      this.fundAccountResponse?.amount - this.fundAccountResponse.charge;
    this.options = {
      amount: this.amount,
      email: this.user.email,
      ref: this.fundAccountResponse.paymentReference,
      key: environment.PayStackKey,
    };
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }
  paymentCancel() {
    this.modalCtrl.dismiss(null, "cancel");
  }

  paymentDone(ref: any) {
    this.modalCtrl.dismiss(ref, "confirm");
  }
}
