import { Component, OnInit } from "@angular/core";
import { Plugins, Capacitor } from "@capacitor/core";
import { AlertController, Platform } from "@ionic/angular";
import { AuthServices } from "./Auth/auth.service";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Keepalive } from "@ng-idle/keepalive";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  constructor(
    private platform: Platform,
    private authService: AuthServices,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private alert: AlertController
  ) {
    this.initializeApp();
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(120);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => (this.idleState = "No longer idle."));
    idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      this.authService.logOff();
      //this.router.navigateByUrl('/auth/login');
    });
    idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will loggedui out in " + countdown + " seconds!";
      // this.presentAlert('',this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(240);
    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      console.log("PINGING SERVER");
      this.authService.refreshUserSession().subscribe(() => {
        this.reset();
      });
    });

    this.reset();
  }
  ngOnInit(): void {
    // this.authService.isAuthenticated().subscribe(isAuth=> {
    //   if(!isAuth){
    //     this.router.navigateByUrl('/auth');
    //     return;
    //   }
    // });
    this.authService
      .readUserFromStorage()
      .pipe(take(1))
      .subscribe(() => {});
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable("SplashScreen")) {
        Plugins.SplashScreen.hide();
      }
    });
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
  async presentAlert(status, reason) {
    const alert = await this.alert.create({
      header: status + " Error",
      message: reason,
      buttons: ["OK"],
    });

    await alert.present();
  }
}
