import { Component, Input, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ProfileService } from "../../Profile/profileService";
import { AuthServices } from "../../Auth/auth.service";
import { Question } from "../../Profile/models/secretQuestionsVm";

@Component({
  templateUrl: "../views/securityQuery.html",
  styleUrls: ['../styles/securityQuery.scss']
})
export class SecurityQueryPage implements OnInit {
  form: FormGroup;
  @Input() email;
  question: Question = { id: 0, question: "" };
  otpMessage;
  constructor(
    private profileService: ProfileService,
    private authService: AuthServices,
    public modalCtrl: ModalController,
  ) {
    //  this.initForm();
    console.log("Email Passed", this.email);
  }
  ngOnInit(): void {
    this.initForm();
    this.authService.currentUser$.subscribe(user => {
        this.email = user.email;
    });
    this.profileService.getRandomSecretQuestion().subscribe((res) => {
        this.question = res.payload;
      });
  }

  initForm() {
    let group = {};
    group["answer"] = new FormControl("", Validators.required);
    this.form = new FormGroup(group);
  }

  submit() {
    this.modalCtrl.dismiss(true, "confirm");
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
