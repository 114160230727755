import { Injectable } from "@angular/core";
import { Plugins } from "@capacitor/core";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Config } from "src/assets/config/config";
import { ClientModel } from "./auth.model";
import { Platform } from "@ionic/angular";
import { map, take } from "rxjs/operators";
import { IUser } from "./models/IUser";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, from, of } from "rxjs";
import { TokenModel } from "./models/TokenModel";
const { Storage } = Plugins;

@Injectable({
  providedIn: "root",
})
export class AuthServices {
  private currentUserSource = new BehaviorSubject<IUser>({
    email: "",
    firstName: "",
    loggedInAsUser: false,
    phoneNumber: "",
    pic: "",
    refresh_token: "",
    roles: [],
    surName: "",
    token: "",
    userId: "",
  });
  private tokenSource = new BehaviorSubject<string>("");
  currentUser$ = this.currentUserSource.asObservable();
  constructor(private platform: Platform, public httpClient: HttpClient) {}
  private storeAuthData(
    token: string,
    expiresIn: any,
    IsUser = true,
    refresh_token,
    photoUrl = null
  ) {
    this.setUser(token, IsUser, refresh_token, photoUrl);
    const data = JSON.stringify({
      token,
      expiresIn,
      refresh_token,
      IsUser,
      photoUrl: photoUrl,
    });
    Plugins.Storage.set({ key: "authData", value: data });
  }
  getAccessToken() {
    return this.tokenSource.value;
  }
  loginAsUser(userModel: any) {
    const client = this.GetClientCredentials();
    const params = new HttpParams({
      fromObject: {
        grant_type: "password",
        username: userModel.username,
        password: userModel.password,
        client_id: client.clientId,
        client_secret: client.clientSecret,
      },
    });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    return this.httpClient
      .post<TokenModel>(
        `${Config.settings.apiServer.AuthUrl}`,
        params,
        httpOptions
      )
      .pipe(
        map((res) => {
          this.storeAuthData(
            res.access_token,
            res.expires_in,
            true,
            res.refresh_token
          );
          return res;
        })
      );
  }

  loginAsClient() {
    const client = this.GetClientCredentials();
    const params = new HttpParams({
      fromObject: {
        grant_type: "client_credentials",
        client_id: client.clientId,
        client_secret: client.clientSecret,
      },
    });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    return this.httpClient
      .post<TokenModel>(
        `${Config.settings.apiServer.AuthUrl}`,
        params,
        httpOptions
      )
      .pipe(
        map((res) => {
          this.storeAuthData(
            res.access_token,
            res.expires_in,
            false,
            res.refresh_token
          );
          return res;
        })
      );
  }
  private GetClientCredentials(): ClientModel {
    if (
      (this.platform.is("mobile") && !this.platform.is("hybrid")) ||
      this.platform.is("desktop")
    ) {
      return new ClientModel(
        Config.settings.apiServer.clientId,
        Config.settings.apiServer.clientSecret
      );
    }
    return new ClientModel(
      Config.settings.apiServer.mobileClientId,
      Config.settings.apiServer.mobileClientSecret
    );
  }
  setUser(
    accessToken: string,
    isUserLoggedIn = true,
    refresh_token,
    photoUrl: string
  ) {
    if (accessToken) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(accessToken);
      console.log("Decoded Token", decodedToken);
      // if(decodedToken.email && decodedToken.email!==''){
      const user: IUser = {
        firstName: decodedToken?.given_name ?? "",
        phoneNumber: decodedToken?.phone_number ?? "",
        email: decodedToken?.useremail ?? "",
        roles: decodedToken?.role || [],
        surName: decodedToken?.family_name ?? "",
        pic: photoUrl,
        token: accessToken,
        loggedInAsUser: isUserLoggedIn,
        refresh_token: refresh_token,
        userId: decodedToken?.id,
      };
      this.currentUserSource.next(user);
      this.tokenSource.next(accessToken);
    }
  }
  public isAuthenticated() {
    const helper = new JwtHelperService();
    let isAuth = false;
    this.currentUser$.pipe(take(1)).subscribe((user) => {
      console.log(user);

      if (user) {
        const isTokenExpired = helper.isTokenExpired(user.token);
        if (!isTokenExpired) {
          return (isAuth = true);
        } else {
          this.logOff();
        }
      }
    });
    return of(isAuth);
  }
  logOff() {
    this.currentUserSource.next(null);
    Plugins.Storage.remove({ key: "authData" });
    window.location.href = "/auth/login";
  }
  autoLogin() {
    return from(Plugins.Storage.get({ key: "authData" })).pipe(
      map((storedData) => {
        if (!storedData || !storedData.value) return null;
        const parsedData = JSON.parse(storedData.value) as {
          token: string;
          expiresIn: any;
          refresh_token: string;
          IsUser;
          photoUrl;
        };
        if (!this.isAuthenticated()) return null;
        this.setUser(
          parsedData.token,
          parsedData.IsUser,
          parsedData.refresh_token,
          parsedData.photoUrl
        );
      })
    );
  }

  isClientAuthenticated() {
    return from(Plugins.Storage.get({ key: "authData" })).pipe(
      map((storedData) => {
        console.log("Stored Data Value Undefined issues", storedData);
        if (!storedData || !storedData.value) return false;
        const parsedData = JSON.parse(storedData.value) as {
          token: string;
          expiresIn: any;
          refresh_token: string;
          IsUser;
        };
        let IsAuth = parsedData.token !== null;
        const helper = new JwtHelperService();
        const isTokenExpired = helper.isTokenExpired(parsedData.token);
        console.log("Stored Data Value is authenticated ", IsAuth);
        if (isTokenExpired) {
          IsAuth = false;
        }
        return IsAuth;
      })
    );
  }
  readUserFromStorage() {
    return from(Plugins.Storage.get({ key: "authData" })).pipe(
      map((storedData) => {
        if (!storedData || !storedData.value) return null;
        const parsedData = JSON.parse(storedData.value) as {
          token: string;
          expiresIn: any;
          refresh_token: string;
          IsUser;
          photoUrl;
        };
        this.setUser(
          parsedData.token,
          parsedData.IsUser,
          parsedData.refresh_token,
          parsedData.photoUrl
        );
      })
    );
  }

  refreshUserSession() {
    const client = this.GetClientCredentials();
    let isUser = false;
    let refresh_token;
    let user: IUser;
    this.currentUser$.pipe(take(1)).subscribe((data) => {
      if (data && data.loggedInAsUser) {
        isUser = data.loggedInAsUser;
        refresh_token = data.refresh_token;
        user = data;
      }
    });

    if (isUser) {
      const params = new HttpParams({
        fromObject: {
          grant_type: "refresh_token",
          client_id: client.clientId,
          client_secret: client.clientSecret,
          refresh_token: refresh_token,
        },
      });
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      };
      return this.httpClient
        .post<TokenModel>(
          `${Config.settings.apiServer.AuthUrl}`,
          params,
          httpOptions
        )
        .pipe(
          map((res) => {
            this.storeAuthData(
              res.access_token,
              res.expires_in,
              true,
              res.refresh_token,
              user.pic
            );
            return res;
          })
        );
    } else {
      return this.loginAsClient();
    }
  }
  hasRole(role: string) {
    let hasRole: Boolean = false;
    this.currentUser$.pipe(take(1)).subscribe((user) => {
      if (user && user.roles != null && user.roles.indexOf(role) === -1) {
        hasRole = false;
      } else {
        hasRole = true;
      }
    });
    return hasRole;
  }
  setProfilePhoto(photo: string) {
    let user = this.currentUserSource.value;

    if (user) {
      user.pic = photo;
    }
    console.log("setProfile", user);
    this.currentUserSource.next(user);
    Plugins.Storage.get({ key: "authData" }).then((data) => {
      let parsedData = JSON.parse(data.value) as {
        token: string;
        expiresIn: any;
        refresh_token: string;
        IsUser;
        photoUrl;
      };

      parsedData.photoUrl = photo;
      const dataObject = JSON.stringify({
        token: parsedData.token,
        expiresIn: parsedData.expiresIn,
        refresh_token: parsedData.refresh_token,
        IsUser: parsedData.IsUser,
        photoUrl: parsedData.photoUrl,
      });
      Plugins.Storage.set({ key: "authData", value: dataObject });
    });
  }
}
