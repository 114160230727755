import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { take } from "rxjs/operators";
import { ProfileService } from "src/app/Profile/profileService";
import { Notification } from "../models/notification.model";
import { v4 as uuidv4 } from "uuid";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private notifications = new BehaviorSubject<Notification[]>([]);
  notifications$ = this.notifications.asObservable();
  constructor(private profileService: ProfileService) {}

  getNotifications() {
    let notificiation = this.notifications.value;
    if (notificiation.length > 0) {
      return of(notificiation);
    }
    this.profileService.profile$.pipe(take(1)).subscribe((profile) => {
      if (profile) {
        if (!profile.hasProfilePicture) {
          notificiation.push({
            Id: uuidv4(),
            description: "You are yet to upload your profile picture.",
            title: "SetUp your photo",
            icon: "person-circle-outline",
            link: "/profile",
          });
        }
        if (!profile.hasSecretQuestion) {
          notificiation.push({
            Id: uuidv4(),
            description: "Protect your account against unauthorized access.",
            title: "Missing Secret Answers",
            icon: "bag-handle-outline",
            link: "profile/secretQuestions",
          });
        }
        if (!profile.hasTransactionPIN) {
          notificiation.push({
            Id: uuidv4(),
            description: "kindly setup your PIN to authenticate transactions",
            title: "Secure your transactions",
            icon: "key-outline",
            link: "profile/secretQuestions",
          });
        }
      }
    });
    this.notifications.next(notificiation);
    return of(notificiation);
  }
}
