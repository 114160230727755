import { FormsModule } from "@angular/forms";
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./Shared/shared.module";
import { Config } from "src/assets/config/config";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpConfigInterceptor } from "./Shared/interceptors/http.configuration.interceptor";
import { MomentModule } from "angular2-moment";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { HttpRequestInterceptor } from "./Shared/interceptors/http.interceptor";
import { AuthServices } from "./Auth/auth.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { Clipboard } from "@ionic-native/clipboard/ngx";
export function initializeApp(appConfig: Config) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MomentModule,
    NgxSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StatusBar,
    SplashScreen,
    Clipboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Config,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Config],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
