
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/Shared/Services/Api.services';
import { Injectable } from '@angular/core';
import { AuthenticationTypes } from '../models/challenge.model';
import { Config } from 'src/assets/config/config';
import { map } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';


@Injectable({
  providedIn:'root'
})
export class ChallengeService extends ApiService {
    baseUrl = `${Config.settings.apiServer.ChallengeUrl}`;
    private challengeSource = new BehaviorSubject<AuthenticationTypes[]>(null);
    challenge$= this.challengeSource.asObservable();
    private userChallengeSource = new BehaviorSubject<AuthenticationTypes>(null);
     userChallenge$ = this.userChallengeSource.asObservable();
  constructor(public httpClient: HttpClient) {
    super(httpClient);
    } 
    getChannelChallenges(){
        var currentValue= this.challengeSource.value;
        // if(currentValue && currentValue.length > 0) return of(currentValue);
       return this.GetAll<AuthenticationTypes[]>(`${this.baseUrl}/ChannelConfig/GetChallenges`)
       .pipe(map(res=> {
           this.challengeSource.next(res.payload);
          return res.payload;
       }))
    }
    getChallengeToUse(amount: number){
      var allChallenges = this.challengeSource.value;
      let transactionAuthentication= allChallenges.find(x=>x.minLimit>=amount && amount<=x.maxLimit);
     this.userChallengeSource.next(transactionAuthentication);
     return of(transactionAuthentication);
    }
}
