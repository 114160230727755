import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AccountsModel } from "src/app/Accounts/models/accountmocel";
import { AccountServices } from "src/app/Accounts/services/account.services";

@Component({
    templateUrl:'../views/account-details.html',
    selector : 'app-account-details',
    styleUrls:['../styles/account-details.scss']
})

export class AccountDetailsPage implements OnInit{
    @Input() account: AccountsModel;

    constructor(public accountService: AccountServices, private modalCtrl: ModalController) {}
    
    ngOnInit(): void {
        console.log(this.account);
    }

    dismiss() {
        this.modalCtrl.dismiss();
      }
}