import { Component, Input, OnInit } from "@angular/core";
import { Notification } from "../models/notification.model";
import { NotificationService } from "../Services/notification.service";

@Component({
  templateUrl: "../views/notification.html",
  selector: "notifications",
  styleUrls: ["../styles/notification.scss"],
})
export class NotificationPage implements OnInit {
  notifications: Notification[];

  constructor(public notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.getNotifications().subscribe((res) => {});
  }
}
