import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AccountsModel } from "src/app/Accounts/models/accountmocel";
import { AccountServices } from "../../Accounts/services/account.services";
import { AccountFundMethod } from "../models/enums";
import { CardPaymentServices } from "../Services/payment.service";
import { AccountDetailsPage } from "./account-details.page";
import { CardFundingPage } from "./card-funding.page";
import { PaymentMethodPage } from "./payment-method.page";

@Component({
  templateUrl: "../views/accounts.card.html",
  selector: "accounts-card",
  styleUrls: ["../styles/account.scss"],
})
export class AccountsCardPage implements OnInit, AfterViewChecked {
  @Input("isDashBoard") isDashBoard: boolean = false;
  @Input() showButtons = true;
  @ViewChild("a") accountSlides;
  reference: string;
  constructor(
    public accountService: AccountServices,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private _cardPaymentService: CardPaymentServices
  ) {}
  showBalance = true;
  accounts: AccountsModel[];
  activeIndex = 0;
  loadingFailed: boolean = false;
  isLoading: boolean = false;
  toggleBalance() {
    this.showBalance = !this.showBalance;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.accountService.GetAllAccounts(this.isDashBoard).subscribe(
      (res) => {
        this.isLoading = false;
        console.log(res);
        this.accounts = res;
      },
      (err) => {
        this.isLoading = false;
        this.loadingFailed = true;
      }
    );
  }

  slideEvent(e) {
    this.accountSlides.getActiveIndex().then((index: number) => {
      console.log(index);
      this.accountService.updateCurrentAccount(index);
    });
  }

  async prompt(account) {
    const modal = await this.modalCtrl.create({
      component: PaymentMethodPage,
      cssClass: "auto-height",
      swipeToClose: true,
    });
    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    if (role === "confirm") {
      if (data === AccountFundMethod.Bank) {
        this.accountDetails(account);
      } else {
        this.fundViaCard(account);
      }
    }
  }

  reloadAccounts() {
    this.isLoading = true;
    this.loadingFailed = false;
    this.accountService.GetAllAccounts(this.isDashBoard).subscribe(
      (res) => {
        console.log(res);
        this.accounts = res;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.loadingFailed = true;
      }
    );
  }

  async accountDetails(account) {
    const modal = await this.modalCtrl.create({
      component: AccountDetailsPage,
      componentProps: { account },
      cssClass: "auto-height",
    });
    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return;
  }

  async fundViaCard(account) {
    const modal = await this.modalCtrl.create({
      component: CardFundingPage,
      componentProps: { account },
    });
    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
}
