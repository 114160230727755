import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
    selector: 'account-payment',
    templateUrl:'../views/account-payment.html',
    styleUrls: ['../styles/account-payment.scss']
})
export class AccountPaymentPage implements OnInit {
    @Input() accounts;
    @Input() disableCards: boolean = false;
    @Input() amount;
    @Input() activeIndex;

    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {
        
    }

    onClose(accNumber) {
        this.modalCtrl.dismiss(accNumber, 'account-select');
    }

    cardSelect(card) {
        console.log(card);
    }
}