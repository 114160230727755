import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { CameraResultType, CameraSource, Capacitor, Plugins } from '@capacitor/core';
import { ActionSheetController, Platform, ToastController } from '@ionic/angular';
import { ImagePickVm } from '../models/imagePickModel';
import { AlertService } from '../Services/alert.service';

@Component({
  selector: "app-image-picker",
  templateUrl: "../views/image-picker.component.html",
  styleUrls: ["../styles/image-picker.component.scss"],
})
export class ImagePickerComponent implements OnInit {
  @Input() showPreview = false;
  @Input() logoShape;
  @Input() allowedExtensions = ['.jpeg', '.jpg', '.png'];
  @Input() defaultLogo = {
    name: null,
    src: 'assets/icon/person-solid.svg'
  };
  @Input()usePicker = false;
  @Input()camera = true;
  @ViewChild("filePicker") filePickerRef: ElementRef<HTMLInputElement>;
  @Output() imagePick = new EventEmitter<ImagePickVm | File>();
  selectedImage: string;

  constructor(
    private platform: Platform,
    public alertService: AlertService,
    private actionSheetCtrl: ActionSheetController,
  ) {}

  ngOnInit() {
  }

  prompt() {
    if (this.usePicker) {
      this.actionSheetCtrl.create({
        header: 'Choose Picture via',
        buttons: [
          { text: 'Camera', handler: () => {
            this.onPickImage();
          }},
          { text: 'Gallery', handler: () => {
            this.filePickerRef.nativeElement.click();;
          }},
          { text: 'Cancel', role: 'cancel'},
        ]
      }).then(actionSheetEl => {
        actionSheetEl.present();
      });
    } else {
      this.onPickImage()
    }
  }

  onPickImage() {
    if (!Capacitor.isPluginAvailable("Camera")) {
      this.filePickerRef.nativeElement.click();
      return;
    }

    Plugins.Camera.getPhoto({
      quality: 50,
      source: CameraSource.Prompt,
      correctOrientation: true,
      height: 320,
      width: 200,
      resultType: CameraResultType.Base64,
    })
      .then((image) => {
        if (
          !this.allowedExtensions.some(
            (item) => item.toLowerCase() == `.${image.format.toLowerCase()}`
          )
        ) {
          this.alertService.showAlert(
            "error",
            `Only ${this.allowedExtensions.join()} files are allowed`
          );
          return;
        }
        this.selectedImage = `data:image/${image.format};base64,${image.base64String}`;
        const imagePicked: ImagePickVm = {
          image: this.selectedImage,
          format: image.format,
          blob: this.base64toBlob(image.base64String, image.format)
        };
        this.imagePick.emit(imagePicked);
      })
      .catch((error) => {
        console.log(error);
        if (this.usePicker) {
          this.filePickerRef.nativeElement.click();
        } else return;
        
      });
  }

  onFileChosen(event: Event) {
    const pickedFile = (event.target as HTMLInputElement).files[0];
    if (!pickedFile) {
      return;
    }
    const fr = new FileReader();
    fr.onload = () => {
      const dataUrl = fr.result.toString();
      this.selectedImage = dataUrl;
      const format = pickedFile.name.split(".").pop();      
      const imagePicked: ImagePickVm = {
        image: this.selectedImage,
        format: format,
        blob: this.base64toBlob(this.selectedImage, format)
      };
      this.imagePick.emit(imagePicked);
    };
    fr.readAsDataURL(pickedFile);
  }

  base64toBlob(base64Data, format) {
    if (format==='jpg') format = 'jpeg'
    const contentType = `image/${format}` || '';
    const sliceSize = 1024;
    const byteCharacters = window.atob(base64Data.replace(`data:image/${format};base64,`, ''));
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
  
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
  
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
