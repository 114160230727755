import { Component, Input, OnInit } from "@angular/core";
import { Advert } from "../models/advert.model";
import { AdvertService } from "../Services/advert.service";

@Component({
    templateUrl:'../views/advert.html',
    selector : 'app-advert',
    styleUrls:['../styles/advert.scss']
})

export class AdvertPage implements OnInit{
    ads: Advert[]
    slideOptions = {
        initialSlide: 1,
        speed: 400,
        autoPlay:true
      };
    constructor(private advertService: AdvertService) {}
    
    ngOnInit(): void {
        this.ads = this.advertService.ads; 
        console.log('oninit');
        console.log(this.ads);
        
               
    }
}