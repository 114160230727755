import { Config } from "./../../../assets/config/config";
import { ApiService } from "src/app/Shared/Services/Api.services";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FundAccountRequestVm } from "../models/fundAccountRequestVm";
import { FundAccountResponseVm } from "../models/fundAccountResponseVm";
import { BehaviorSubject, of } from "rxjs";
import { CardVm } from "../models/cardVm";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CardPaymentServices extends ApiService {
  private paymentUrl = `${Config.settings.apiServer.PaymentsUrl}/api/v1/cards`;
  private fundAccountUrl = `${Config.settings.apiServer.PaymentsUrl}/api/v1/fundAccount`;
  private _cards = new BehaviorSubject<CardVm[]>([]);
  cards$ = this._cards.asObservable();
  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  GetPaymentOption() {
    return this.GetAll<any>(`${this.paymentUrl}/provider`);
  }
  GetUserCards(reload = false) {
    if (!reload) {
      let cards = this._cards.value;
      if (cards && cards.length > 0) return of(cards);
    }

    return this.GetAll<CardVm[]>(`${this.paymentUrl}/getusercards`).pipe(
      map((res) => {
        this._cards.next(res.payload);
        return res.payload;
      })
    );
  }
  GetUserCardsAndBankAccounts() {
    return this.GetAll<any>(`${this.paymentUrl}/getusercardsandBankAccounts`);
  }
  GenerateReference() {
    return this.GetAll<string>(`${this.paymentUrl}/generateRef`);
  }

  addCard(model: any) {
    return this.post<any>(model, `${this.paymentUrl}/addcard`);
  }

  GetUserBankAccounts() {
    return this.GetAll(`${this.paymentUrl}/getuseraccounts`);
  }

  GetBanks() {
    return this.GetAll(`${this.paymentUrl}/getallbanks`);
  }
  verifyAccount(bankCode, accountNumber) {
    return this.GetAll(
      `${this.paymentUrl}/verifybankAccount/${bankCode}/${accountNumber}`
    );
  }

  addBankAccount(model: any) {
    return this.post(model, `${this.paymentUrl}/addbankaccount`);
  }

  fundAccountWithCardInit(model: FundAccountRequestVm) {
    return this.post<FundAccountResponseVm>(
      model,
      `${this.fundAccountUrl}/withCardInitiate`
    );
  }
  fundAccountWithCardComplete(model: FundAccountRequestVm) {
    return this.post<FundAccountResponseVm>(
      model,
      `${this.fundAccountUrl}/withCardFinalize`
    );
  }
}
