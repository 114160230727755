

export interface Beneficiary{
    id: number,
    name: string,
    value: string,
    product: string,
    productImage: string,
    productCode: string,
    beneFiciaryType: BeneficiaryTypes
}

export enum BeneficiaryTypes{
    
    Transfer = 1,
    Airtime = 2,
    Data = 3,
    Bills = 4
    
}